import axios from 'axios'

import type { AxiosInstance } from 'axios'
import type { App, InjectionKey } from 'vue'
import type { Language } from '@/types'

interface AxiosOptions {
  language: Language
}

export const AxiosKey = Symbol('axiosKey') as InjectionKey<AxiosInstance>

export const createAxios = (options: AxiosOptions) => {
  const instance = axios.create({
    headers: {
      'Accept-Language': options.language,
    },
  })

  return {
    install: (app: App) => {
      app.provide(AxiosKey, instance)
    },
    instance,
  }
}
