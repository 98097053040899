import type { Country } from '@/types'

/**
 * Countries
 */
const country: { default: Country; current?: Country } = {
  default: 'be',
}

const countriesAvailable = ['be', 'lu'] as const

const setCountry = (hostname: string) => {
  let c = process.env.EDENRED_COUNTRY

  if (!c) {
    // prod: 'edenred.be'
    // staging: 'edenred-website-v2-be-app.staging.epic-sys.io'
    // dev (browser): 'localhost'
    // dev (server): 'undefined'
    c = hostname.includes('staging.epic-sys.io')
      ? hostname.split('.')[0].split('-').slice(0, -1).pop()
      : hostname.split('.').pop()
  }

  if (countriesAvailable.includes(c as Country)) {
    country.current = c as Country
  } else {
    country.current = country.default
  }

  return country.current
}

const getCountry = () => {
  if (country.current) {
    return country.current
  }

  throw new Error('Country not set')
}

export { countriesAvailable, setCountry, getCountry }
