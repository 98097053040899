import { defineStore } from 'pinia'

import { getApiUrl } from '@/config/app'
import { getLanguageDefault } from '@/config/languages'
import { logger } from '@/utils/logger'

import type { HeadAttrs } from '@vueuse/head'
import type { AxiosError, AxiosInstance, AxiosResponse } from 'axios'
import type { Language, Picture } from '@/types'

// Flamel
interface NavLink {
  title: string
  url: string
}

// Edenred
interface Link {
  label: string
  url: string
  target?: string
}

interface NavItem {
  label: string
  text?: string
  target?: string
  url: string
  picture?: object
  children?: Link[] &
    {
      classes: string
    }[]
}
interface Announce {
  color: 'red' | 'blue'
  text: string
  link?: Link
}

export interface ChromeState {
  // Flamel
  i18n: Record<string, string>
  // main: NavLink[]
  // nav: NavLink[]
  script: HeadAttrs[]
  language: Language
  // Edenred
  coordinates: Record<string, string>
  social_networks: Record<string, string>
  main: {
    label: string
    text?: string
    url: string
    isCta?: boolean
    classes?: string
    caption?: string
    children?: {
      label: string
      url: string
      classes?: string
    }[]
    last_news?: {
      title: string
      url: string
      picture: Picture
    }
    sector: string
  }[]
  main_menu: []
  myedenred: Record<string, string>
  secondary_menu: {
    url: string
    label: string
  }[]
  footer_menu: NavItem[]
  legal_menu: NavItem[]
  cookies: string
  announce?: Announce
}

export interface ChromeResponse {
  main: NavLink[]
  script: HeadAttrs[]
}

export type Chrome = ChromeState

/* eslint-disable camelcase */
const defaultState = (): ChromeState => ({
  // flamel
  i18n: {},
  // main: [],
  // nav: [],
  script: [],
  language: getLanguageDefault(),
  coordinates: {},
  social_networks: {},
  main_menu: [],
  main: [],
  myedenred: {},
  secondary_menu: [],
  footer_menu: [],
  legal_menu: [],
  cookies: '',
})
/* eslint-enable camelcase */

export const useChromeStore = defineStore('chrome', {
  state: defaultState,
  actions: {
    async fetchChrome(
      language: Language = getLanguageDefault(),
      axios: AxiosInstance
    ) {
      logger.info('[fetchChrome]', language, `${getApiUrl()}/chrome`)
      try {
        const response = (await axios.get(
          `${getApiUrl()}/chrome`
        )) as AxiosResponse<ChromeResponse>
        const data = response?.data
        // const { main } = data

        this.$patch(data)
        this.language = language
      } catch (error) {
        if ((error as AxiosError).isAxiosError) {
          logger.error(
            '[fetchChrome]',
            (error as AxiosError).code,
            (error as AxiosError).message
          )
        } else {
          logger.error('[fetchChrome]', (error as Error).message)
        }
      }
    },
  },
})
